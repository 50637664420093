import { css, StyleSheet } from 'aphrodite/no-important'
import React from 'react'

export interface ColumnsProps {
    children: JSX.Element
    count: 2 | 3
    marginBottom?: number
}

const Columns: React.FC<ColumnsProps> = ({ children, count, marginBottom }: ColumnsProps): JSX.Element => {
    const styles = StyleSheet.create({
        Columns: {
            width: '100%',
            display: 'grid',
            gridColumnGap: 15,
            gridRowGap: 60,
            marginBottom: marginBottom,

            '@media (max-width: 1023px)': {
                gridTemplateColumns: '1fr',
                gridRowGap: 15,
                gridTemplateRows: 'repeat(auto-fit, 1fr)',
            },
        },
        two: {
            gridTemplateColumns: 'repeat(2, calc(50% - 7.5px))',
        },
        three: {
            gridTemplateColumns: 'repeat(3, calc(33.33% - 10px))',
        },
    })

    return <div className={css(styles.Columns, count === 3 ? styles.three : styles.two)}>{children}</div>
}

Columns.defaultProps = {
    count: 2,
    marginBottom: 15,
}

export default Columns
