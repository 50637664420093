import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import Columns from '../components/Columns'
import Content from '../components/Content'
import Headline from '../components/Headline'
import PostPreview from '../components/PostPreview'
import Stage from '../components/Stage'
import Default from '../templates/default'

export interface File {
    url: string
    fileName: string
    contentType: string
}

export interface Fluid {
    aspectRatio: number
    src: string
    srcSet: string
    sizes: string
}

export interface ChildImageSharp {
    fluid: Fluid
}

export interface LocalFile {
    childImageSharp: ChildImageSharp
}

export interface StageImage {
    file: File
    localFile: LocalFile
}

export interface TeaserText {
    teaserText: string
}

export interface Node {
    title: string
    url: string
    markAsHighlight: boolean
    date: string
    stageImage: StageImage
    teaserText: TeaserText
}

export interface Posts {
    nodes: Node[]
}

export interface Data {
    posts: Posts
    stageImage: {
        childImageSharp: {
            fluid: FluidObject
        }
    }
}

export interface UnsereGeschichtenProps {
    data: Data
}

const UnsereGeschichten = ({ data }: UnsereGeschichtenProps): JSX.Element => {
    return (
        <Default>
            <Content>
                <Stage image={data.stageImage.childImageSharp} small />

                <Headline type="h2" text="HIGHLIGHTS" horizontalLine />

                <Columns count={2} marginBottom={60}>
                    {data.posts.nodes
                        .filter(post => post.markAsHighlight === true)
                        .map(post => {
                            const d = new Date(post.date)

                            return (
                                <PostPreview
                                    key={post.url}
                                    headline={post.title}
                                    text={post.teaserText.teaserText}
                                    date={`${d.getUTCDate()}.${d.getUTCMonth()}.${d.getUTCFullYear()}`}
                                    image={post.stageImage.localFile.childImageSharp.fluid}
                                    linkTarget={`/unsere-geschichten/${post.url}/`}
                                />
                            )
                        })}
                </Columns>

                <Headline type="h2" text="ALLE GESCHICHTEN" horizontalLine />

                <Columns count={2} marginBottom={60}>
                    {data.posts.nodes.map(post => {
                        const d = new Date(post.date)
                        return (
                            <PostPreview
                                key={post.url}
                                headline={post.title}
                                text={post.teaserText.teaserText}
                                date={`${d.getUTCDate()}.${d.getUTCMonth()}.${d.getUTCFullYear()}`}
                                image={post.stageImage.localFile.childImageSharp.fluid}
                                linkTarget={`/unsere-geschichten/${post.url}/`}
                            />
                        )
                    })}
                </Columns>
            </Content>
        </Default>
    )
}

UnsereGeschichten.propTypes = {
    children: PropTypes.any,
}

UnsereGeschichten.defaultProps = {}

export const query = graphql`
    query {
        stageImage: file(relativePath: { eq: "stage/unsere-geschichten.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200, maxHeight: 250, quality: 75, cropFocus: CENTER) {
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                }
            }
        }
        posts: allContentfulBlogPost {
            nodes {
                title
                url
                markAsHighlight
                date
                stageImage {
                    file {
                        url
                        fileName
                        contentType
                    }
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 390, maxHeight: 390, quality: 75, cropFocus: CENTER) {
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                sizes
                            }
                        }
                    }
                }
                teaserText {
                    teaserText
                }
            }
        }
    }
`

export default UnsereGeschichten
