import { css, StyleSheet } from 'aphrodite/no-important'
import { Link } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React from 'react'

export interface PostPreviewProps {
    image: FluidObject
    headline: string
    text: string
    date: string
    linkTarget: string
}

const PostPreview: React.FC<PostPreviewProps> = ({
    image,
    headline,
    text,
    date,
    linkTarget,
}: PostPreviewProps): JSX.Element => (
    <Link className={css(styles.PostPreview)} to={linkTarget}>
        {image && <Img className={css(styles.image)} fluid={image} />}
        <div className={css(styles.headline)}>{headline}</div>
        <div className={css(styles.text)}>
            <span className={css(styles.date)}>{date} </span>| {text}
        </div>
    </Link>
)

const styles = StyleSheet.create({
    PostPreview: {
        display: 'flex',
        flexDirection: 'column',
        color: '#000',
        textDecoration: 'none',

        '@media (max-width: 768px)': {
            marginBottom: 60,
        },
    },
    image: {
        objectFit: 'cover',
        width: '100%',
        flex: '0 1 350px',
        marginBottom: 30,

        '@media (max-width: 768px)': {
            marginBottom: 10,
        },
    },
    headline: {
        fontSize: 24,
        textTransform: 'uppercase',
        marginBottom: 20,

        '@media (max-width: 768px)': {
            marginBottom: 10,
        },
    },
    text: {
        fontSize: 18,
        fontWeight: 300,
        display: 'inline',
    },
    date: {
        fontWeight: 700,
    },
})

export default PostPreview
